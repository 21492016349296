import React from 'react';
import { Container, Col, Row, ListGroup } from 'react-bootstrap';
import { usePages } from '../../../Context/PagesContext';

import './Footer.scss';

import logo from '../../../assets/mainLogo_no_background.png';

const Footer = () => {
    const pages = usePages();

    const footerPages = [
        ...pages,
        { title: 'Terms', target: '/terms-of-service' },
        { title: 'Privacy', target: '/privacy-policy' }
    ];
    
    return (
        <Container fluid className='text-white py-3 footer-container'>
            <Container>
                <Row className='d-flex justify-content-center mb-5'>
                    <Col lg={10}>
                        <Row className='upper-footer-container'>
                            <Col lg={4}>
                                <Row className='text-center mb-5'>
                                    <img className='footer-logo d-lg-block' src={logo} alt='Colligan Tech Solutions LLC logo' />
                                </Row>
                            </Col>
                            <Col lg={4} className='pt-lg-3 footer-about-blurb'>
                                <h5 className='text-start fs-2 footer-header mb-5'>About</h5>
                                <p className='text-start footer-about-summary'>I specialize in custom web development and data solutions for businesses and individuals. My services include building responsive websites and creating efficient automation tools to enhance productivity. <a className='action-link fw-bold' href='/contact'>Contact me today</a> to get started.</p>
                            </Col>
                            <Col lg={4} className='pt-lg-3 mb-sm-3'>
                            <h5 className='text-start fs-2 footer-header mb-5'>Links</h5>
                                <ListGroup>
                                    {footerPages.map(({title, target}, i) => (
                                        <ListGroup.Item key={i} className='px-0 bg-transparent text-white text-start border-0 pt-0'>
                                            <a href={target} className='action-link fw-bold'>{title}</a>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='text-center footer-anchor p-0'>
                    <span>&copy; {new Date().getFullYear()} Colligan Tech Solutions LLC. All rights reserved.</span>
                </Row>
            </Container>
        </Container>
    );
};

export default Footer; 
